import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';
import { USER_INFO } from '@/router';

import Switcher from '@/components/Switcher';
import InfoMessage from '@/components/generic/InfoMessage';
import Loader from '@/components/generic/Loader';
import Modal from '@/components/generic/Modal';
import Cookiebar from '@/components/generic/Cookiebar';

const mapStateToProps = (state) => {
  const route = state.location.type;

  return {
    isViewingData: route === USER_INFO,
    isLoading: selectors.getLoadingState(state),
    isLoggedIn: selectors.getIsLoggedIn(state),
    unreadMessageCount: selectors.getUserMessageUnreadCount(state),
    onboardingProgress: selectors.getOnboardingProgress(state),
    infoMessages: selectors.getInfoAlerts(state),
    modal: selectors.getModal(state),
    errors: selectors.getGlobalErrors(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDismissError: (errorId) => dispatch(actions.removeError(errorId)),
  onDismissAlert: (alertId) => dispatch(actions.removeAlert(alertId)),
});

const Page = ({
  isLoggedIn = false,
  isLoading = false,
  isViewingData = false,
  onboardingProgress = {},
  infoMessages = [],
  modal = {},
  errors,
  onDismissError,
  onDismissAlert,
}) => {
  return (
    <div className={isLoading ? 'page--content-loading' : 'page--content-loaded'}>
      <Loader isLoading={isLoading} />
      <Cookiebar />
      {/* eslint-disable react/no-array-index-key */}
      {/* It is possible that infoMessages and errors with the same content are being displayed */}
      {infoMessages.map((message, index) => (
        message &&
          <InfoMessage message={message} key={index} isPositive={true} onDismiss={onDismissAlert.bind({}, index)} />
      ))}
      {errors.map((error, index) => (
        error &&
          <InfoMessage message={error} key={index} isPositive={false} onDismiss={onDismissError.bind({}, index)} />
      ))}
      {/* eslint-enable react/no-array-index-key */}
      {modal.showModal &&
        <Modal {...modal} />
      }

      <Switcher />
    </div>
  );
};

Page.propTypes = {
  isLoading: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isViewingData: PropTypes.bool,
  onboardingProgress: PropTypes.object,
  infoMessages: PropTypes.array,
  errors: PropTypes.array,
  modal: PropTypes.object,
  onDismissAlert: PropTypes.func,
  onDismissError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
