import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InfoMessage = ({ message, isPositive, onDismiss }) => {
  const classes = classnames({
    'info-message': true,
    'info-message--positive': isPositive,
    'info-message--negative': !isPositive,
  });

  return (
    <div role='alertdialog' aria-labelledby='dialogTitle' className={classes}>
      <div role='document' tabIndex='0' className='info-message__inner'>
        <div id='dialogTitle' className='info-message__text'>
          {message}
        </div>

        <button className='info-message__close-button' onClick={onDismiss} autoFocus={true}>
          <span className='info-message__close-text'>Infonachricht schließen</span>
        </button>
      </div>
    </div>
  );
};

InfoMessage.propTypes = {
  message: PropTypes.string,
  isPositive: PropTypes.bool,
  onDismiss: PropTypes.func,
};

export default InfoMessage;
