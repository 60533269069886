import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Provider } from 'react-redux';

import '@/stylesheets/manifest.scss';

import store from '@/store';

import Page from '@/components/specific/Page';

const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;
const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;
const SENTRY_ENABLED =  process.env.REACT_APP_SENTRY_ENABLED;

if(SENTRY_ENABLED) {
  Sentry.init({
  dsn: SENTRY_URL,
  environment: SENTRY_ENVIRONMENT
  })
}

ReactDOM.render(
  <Provider store={store}>
    <Page />
  </Provider>,
  document.body.appendChild(document.createElement('div')),
);
