import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      finishedLoading: false,
      startedLoading: false,
      timeoutId: null,
    };
  }

  // TODO: Update to maintain compatibility with future versions of React.
  // https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  UNSAFE_componentWillReceiveProps(nextProps) {
    // start animation when loading starts
    if (!this.props.isLoading && nextProps.isLoading) {
      this.setState({
        startedLoading: true,
        finishedLoading: false,
        isHidden: false,
      });
      clearTimeout(this.state.timeoutId);
    }
    // finish animation when loading stops
    if (this.props.isLoading && !nextProps.isLoading) {
      this.setState({
        startedLoading: false,
        finishedLoading: true,
        isHidden: false,
        timeoutId: setTimeout(() => {
          this.setState({
            finishedLoading: false,
            isHidden: true,
          });
        }, 700), // hide loader after animation has ended
      });
    }
  }

  render() {
    const classes = classnames('loader', {
      'loader--start': this.state.startedLoading,
      'loader--stop': this.state.finishedLoading,
    });

    return (
      <div
        className={classes}
        aria-label='Inhalte werden geladen'
        aria-live='polite'
        aria-hidden={this.state.isHidden}
      />
    );
  };
}

Loader.propTypes = {
  isLoading: PropTypes.bool,
};

export default Loader;
