import React from 'react';
import Cookies from 'universal-cookie';
import classnames from 'classnames';

import * as texts from '@/constants/texts';

class Cookiebar extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.state = { isCookiebarActive: this.cookies.get('dfgCookieNotice') ? false : true };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));

    this.cookies.set('dfgCookieNotice', '1', {
      path: '/',
      expires: date,
    });

    this.setState(prevState => ({
      isCookiebarActive: !prevState.isCookiebarActive,
    }));
  }

  render() {
    const faqListClasses = classnames('cookiebar', {
      'cookiebar--visible': this.state.isCookiebarActive,
    });

    return (
      <section
        className={faqListClasses}
        aria-describedby='cookieconsent:desc'
        aria-label='cookieconsent'
        aria-live='polite'
        role='dialog'
      >
        <div className='cookiebar__inner'>
          <div id='cookieconsent:desc' className='cookiebar__text'>
            {texts.cookiebar.text}
            <a
              className='cookiebar__link'
              href='https://www.der-zukunftsfonds.de/datenschutz'
              aria-label={texts.cookiebar.linkLabel}
              role='button'
              tabIndex='0'
              target='_blank'
              rel='noopener noreferrer'
            >
              {texts.cookiebar.linkText}
            </a>.
          </div>
          <button
            className='cookiebar__close'
            aria-label={texts.cookiebar.buttonLabel}
            tabIndex='0'
            onClick={this.handleClick}
          >
            {texts.cookiebar.buttonText}
          </button>
        </div>
      </section>
    );
  }
}

export default Cookiebar;
