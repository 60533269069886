import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AriaModal from 'react-aria-modal';

import * as actions from '@/actions/creators';

import Button from '@/components/generic/Button';

const applicationNode = document.querySelector('#app');

const mapStateToProps = (state, ownProps) => ({
  showCtaButton: !!ownProps.modalAction,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onConfirm: (e) => {
    e.preventDefault();

    if(!ownProps.keepOnConfirm) {
      dispatch(actions.hideModal());
    }
    dispatch(ownProps.modalAction());
  },
  onClose: () => dispatch(actions.hideModal()),
});

const Modal = ({
  showCloseButton = true,
  showCtaButton = false,
  heading = '',
  text = '',
  ctaButtonLabel = 'Bestätigen',
  closeButtonLabel = 'Schließen',
  buttons = [],
  onConfirm,
  onClose,
  children,
}) => (
  <AriaModal
    applicationNode={applicationNode}
    dialogClass='modal__box'
    dialogId='a11y-modal'
    focusDialog={true}
    includeDefaultStyles={false}
    onExit={onClose}
    titleId='a11y-title'
    underlayClass='modal'
    underlayColor={false}
  >
    <div className='modal__content'>
      <header className='modal__header'>
        <h1 id='a11y-title' className='modal__title'>{heading}</h1>
      </header>

      <div className='modal__main' tabIndex='0'>
        <form onSubmit={onConfirm || onClose}>
          <p className='modal__text'>{text}</p>

          {children}

          {showCtaButton &&
            <Button type='button' onClick={onConfirm}>
              {ctaButtonLabel}
            </Button>
          }

          <div className='modal__back-button'>
            {buttons.map(button => (
              <Button
                type='button'
                onClick={button.action}
                buttonStyle='outlined'
                key={button.label}
              >
                {button.label}
              </Button>
            ))}
          </div>
        </form>
      </div>

      {showCloseButton &&
        <button className='modal__close-button' type='button' onClick={onClose}>
          <span className='modal__close-text'>
            {closeButtonLabel}
          </span>
        </button>
      }
    </div>
  </AriaModal>
);

Modal.propTypes = {
  showCtaButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  heading: PropTypes.string,
  text: PropTypes.string,
  ctaButtonLabel: PropTypes.string,
  closeButtonLabel: PropTypes.string,
  buttons: PropTypes.array,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
